import React, { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import useCompany from 'hooks/api/useCompany';
import pencilSvg from 'assets/img/newPencil.svg';
import delSvg from 'assets/img/newDelete.svg';
import reloadSvg from 'assets/img/reload.svg';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import ChangeStatusModal from 'components/modal/ChangeStatusModal';
import { changeStatusCompany, deleteCompanyById } from 'api/company';
import { CompanyStatus } from 'types/CompanyTypes';

type PropsT = {
  name: string;
  updateCompany: () => void;
  status?: string;
};

const ActionButton: FC<PropsT> = ({ name, updateCompany, status }) => {
  console.log('-->', status);
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { companyId } = useParams();
  const { companyData } = useCompany();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false);

  const getButtonList = () => {
    const buttonList = [
      {
        image: pencilSvg,
        title: 'forms.activationOrder.editClient',
        style: { color: '#838998' },
        onClick: () => history.push(`${urls.getCompany()}/edit/${companyId}`),
      },
      {
        image: reloadSvg,
        title: 'company.changeStatus',
        style: { color: '#838998' },
        onClick: () => (status === 'active' ? {} : setIsOpenChangeStatusModal(true)),
      },
    ];

    if (companyData.status !== 'deleted') {
      buttonList.push({
        image: delSvg,
        title: 'forms.activationOrder.deleteClient',
        style: { color: '#EC3E72' },
        onClick: () => (status !== 'new' ? {} : setIsDeleted(true)),
      });
    }

    return buttonList;
  };

  return (
    <div>
      <ViewGeneralActions buttonList={getButtonList()} />
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title={name}
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          deleteCompanyById(companyId)
            .then(() => history.push(urls.getCompany()))
            .catch(() => {});
        }}
      />
      {isOpenChangeStatusModal && (
        <ChangeStatusModal
          currentStatus={companyData.company_info?.company.status}
          isOpen={isOpenChangeStatusModal}
          onClose={() => setIsOpenChangeStatusModal(false)}
          handleConfirmClick={(status) => {
            setIsOpenChangeStatusModal(false);
            // @ts-ignore
            changeStatusCompany(companyId, status as CompanyStatus)
              .then(() => {
                updateCompany();
              })
              .catch(console.error);
          }}
        />
      )}
    </div>
  );
};

export default ActionButton;
